import { FillWordsStore } from './fillWordsStore.ts'
import { Badge } from '../../shared/ui/card/card.tsx'
import { cn } from '../../shared/lib/utils.ts'
import { useLazyRef } from '../../shared/lib/hooks.ts'
import { useSnapshot } from 'valtio/react'
import { CompleteButton, ExerciseHeader } from './exerciseComponents.tsx'
import { FormattedMessage } from 'react-intl'
import { ClickableWordsWithPopover } from '../../shared/ui/ClickableWordsWithPopover/ClickableWordsWithPopover.tsx'
import { useStores } from '../chats/chatView.tsx'

export type FillWordText = readonly (string | { word: string })[]
interface FillWordsProps {
  texts: readonly FillWordText[]
  completed: boolean
  onComplete: (result: string) => void
}
export const FillWords = (props: FillWordsProps) => {
  const store = useLazyRef(() => new FillWordsStore(props.texts)).current
  const state = useSnapshot(store.state)

  return (
    <div>
      <ExerciseHeader
        title={<FormattedMessage id="exercise.fillWords.title" />}
        subtitle={<FormattedMessage id="exercise.fillWords.subtitle" />}
      />

      <div className="mb-24 flex gap-24">
        <div className="flex flex-wrap gap-8">
          {state.answers.map((meaning, i) => (
            <Badge
              rounded={false}
              bg="violetd"
              className="cursor-pointer justify-center font-bold"
              size="small"
              key={i}
              onClick={() => {
                store.selectWord(meaning)
              }}
            >
              {meaning}
            </Badge>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-12">
        {props.texts.map((text, i) => (
          <Sentence index={i} key={i} text={text} store={store} />
        ))}
      </div>

      <CompleteButton
        bg="blue-gradient"
        completed={props.completed}
        onComplete={() => {
          props.onComplete(store.getResult())
        }}
      />
    </div>
  )
}

function Sentence(props: {
  index: number
  text: FillWordText
  store: FillWordsStore
}) {
  const chatStore = useStores().chatStore
  const chatState = useSnapshot(chatStore.state)
  const state = useSnapshot(props.store.state)
  const words = state.words
  const selectedWords = state.selectedWords
  const selectedIndex = state.selectedIndex
  return (
    <div className="font-semibold">
      <span className="mr-8 inline-block text-14 font-bold">
        {props.index + 1}.
      </span>
      {props.text.map((part, i) => {
        if (typeof part === 'string') {
          return (
            <ClickableWordsWithPopover
              key={i}
              className="inline-block"
              disabled={false}
              selectedWords={chatState.selectedWords}
              onClick={(word, add) => {
                void chatStore.handleWord(word, add)
              }}
            >
              {part}
            </ClickableWordsWithPopover>
          )
        } else {
          const wordIndex = words.indexOf(part.word)
          const selected = selectedWords[wordIndex]
          return (
            <Badge
              inline={true}
              size="small"
              className={cn(
                'mx-4 cursor-pointer justify-center border-2 align-middle font-bold',
                !selected && 'min-w-64',
                wordIndex == selectedIndex
                  ? 'border-main'
                  : 'border-transparent',
              )}
              onClick={() => {
                props.store.setCurrentIndex(wordIndex)
              }}
              rounded={false}
              key={part.word}
              bg={
                selected
                  ? part.word == selected
                    ? 'green-light'
                    : 'alert-light'
                  : 'gray'
              }
            >
              {selected}
            </Badge>
          )
        }
      })}
    </div>
  )
}
