import React, { Fragment, useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import {
  ChatMessageWithIndex,
  ChatStore,
  isExercise,
  isImage,
  MessageContent,
} from './chatStore.ts'
import { useSnapshot } from 'valtio/react'
import { ChatAudioRecordStore } from './chatAudioRecordStore.ts'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { classed } from '@tw-classed/react'
import {
  cn,
  emptyFn,
  formatTimer,
  getLast,
  range,
  removeTextTag,
  splitBy,
  toggleSet,
  useMount,
} from '../../shared/lib/utils.ts'
import { DotsAnimation, Spinner } from '../../shared/ui/spinner.tsx'
import { TextWithTooltips } from './textWithTooltips.tsx'
import { useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { AnimatePresence, motion } from 'framer-motion'
import { match, P } from 'ts-pattern'
import {
  createStoreContext,
  useLazyRef,
  useStoreContext,
} from '../../shared/lib/hooks.ts'
import { ClickableWordsWithPopover } from '../../shared/ui/ClickableWordsWithPopover/ClickableWordsWithPopover.tsx'
import { Badge } from '../../shared/ui/card/card.tsx'
import { FillWords } from '../exercises/fillWords.tsx'
import { MatchWords } from '../exercises/matchWords.tsx'
import { Vocabulary } from '../../shared/api/chatApi.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import { ErrorBoundary } from '@sentry/react'
import {
  Drawer,
  DrawerBar,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from '../../shared/ui/drawer/drawer.tsx'
import { useNavigate } from 'react-router-dom'
import { isWebDomain, urls } from '../../shared/urls.ts'
import { inputClassBase } from '../../shared/ui/textInput/textInput.tsx'
import { proxy } from 'valtio'
import { WithTooltip } from '../../shared/ui/tooltip/tooltip.tsx'
import {
  ImageHideBeforeLoad,
  LessonCompleteImage,
} from '../../shared/ui/images/images.tsx'
import { WriteTheGapss } from '../exercises/writeTheGaps.tsx'
import { DemoChoosePlan, VocabularyContainer } from './demoChat.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { convertToId } from '../../shared/lib/stringUtils.ts'
import { Soundwave } from '../../shared/ui/soundwave/soundwave.tsx'
import { useAppModule } from '../appContext.ts'
import { BuyTrial } from '../programs/buyTrial.tsx'
import { RateLesson } from './rateLesson/rateLesson.tsx'
import { FillTheGaps } from '../exercises/fillTheGaps/fillTheGaps.tsx'
import { AnswerStatusDrawer } from '../exercises/answerStatusDrawer.tsx'

export function messageStageElementId(stage: string | undefined) {
  return stage ? `__message-stage-${convertToId(stage)}` : ''
}

export const ChatStoreContext = createStoreContext<{
  chatStore: ChatStore
  recordStore: ChatAudioRecordStore
}>()

export function useStores() {
  return useStoreContext(ChatStoreContext)
}

const MessageText = classed.div(
  'relative flex flex-col gap-12 whitespace-pre-wrap px-16 py-12 text-14 font-semibold sm:flex-row sm:items-center',
  {
    variants: {
      is_ai: {
        false: 'ml-24 min-w-230 rounded-l-18 rounded-tr-18 bg-user-message',
        true: 'mr-8 w-full rounded-r-18 rounded-tl-18 bg-edman-message',
      },
    },
  },
)

interface MessageViewProps {
  message: ChatMessageWithIndex
  loading: boolean
  showText: boolean
  history?: boolean
  className?: string
}

const MessageButton = classed.button(
  'flex size-28 items-center justify-center rounded-full bg-edman-message text-message-button',
)

function MessageButtons(props: { message: ChatMessageWithIndex }) {
  const store = useStores().chatStore
  const intl = useIntl()
  const translationLoading =
    props.message.showTranslation && !props.message.translation

  return (
    <div className="flex shrink-0 items-center gap-8">
      <WithTooltip tooltip={intl.formatMessage({ id: 'Translate' })}>
        {translationLoading ? (
          <Spinner />
        ) : (
          <MessageButton
            type="button"
            onClick={() => {
              if (!store.state.loading) {
                void store.toggleMessageTranslate(props.message.id)
              }
            }}
          >
            <Icon
              size="little"
              iconName="translate"
              className={cn(
                'cursor-pointer transition-colors',
                props.message.showTranslation && 'text-main',
              )}
            />
          </MessageButton>
        )}
      </WithTooltip>
      <PlayButton hint={false} messageId={props.message.id} />
    </div>
  )
}

function PlayButton(props: { hint: boolean; messageId: number }) {
  const store = useStores().chatStore
  const intl = useIntl()
  return (
    <WithTooltip tooltip={intl.formatMessage({ id: 'Play again' })}>
      <MessageButton
        className="pl-2"
        type="button"
        onClick={() => {
          if (!store.state.loading) {
            void store.play(props.messageId, true, props.hint)
          }
        }}
      >
        <Icon
          size="xs"
          iconName="playMessage"
          className={cn('cursor-pointer')}
        />
      </MessageButton>
    </WithTooltip>
  )
}

function PlayProgress(props: { messageId: number }) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const value =
    props.messageId != state.playingMessage?.messageId
      ? 0
      : state.playingMessage.progress * 100
  return <ProgressBar value={value} />
}

function MessageView(props: MessageViewProps) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const tooltips = (props.message.ai_comment ?? []).map((x) => ({
    text: x.text,
    tooltip: (
      <>
        <b>{x.correct}</b> <br /> {x.rule}
      </>
    ),
  }))
  const showText = !props.message.is_ai || props.showText

  const messageText = props.message.text
  const translation =
    props.message.translation && removeTextTag(props.message.translation)
  return (
    <div
      id={messageStageElementId(props.message.stage)}
      className={cn(
        'flex',
        props.message.is_ai ? 'items-end' : 'justify-end',
        props.className,
      )}
    >
      <MessageText is_ai={props.message.is_ai}>
        {props.message.is_ai && (
          <img
            src="/images/edman.jpg"
            className="hidden size-40 shrink-0 overflow-hidden rounded-full sm:inline"
            alt=""
          />
        )}
        {props.loading ? (
          <>
            <div
              className={cn(
                'flex',
                !props.message.is_ai && 'w-full justify-end',
              )}
            >
              <DotsAnimation />
            </div>
            {props.message.is_ai && state.responseLoadingText && (
              <div className="mt-4">{state.responseLoadingText}</div>
            )}
          </>
        ) : (
          <>
            {showText ? (
              props.message.is_ai ? (
                <div className="flex-1">
                  <ClickableWordsWithPopover
                    key={messageText}
                    animateSpeed={
                      state.lastMessageId == props.message.id
                        ? 'fast'
                        : undefined
                    }
                    disabled={false}
                    className="flex-1"
                    selectedWords={state.selectedWords}
                    onClick={(word, add) => {
                      void store.handleWord(word, add)
                    }}
                  >
                    {messageText}
                  </ClickableWordsWithPopover>
                  <AnimatePresence>
                    {props.message.showTranslation && translation && (
                      <motion.div
                        className="overflow-hidden text-light"
                        initial={{ opacity: 0, height: '0' }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: '0' }}
                      >
                        <div className="pt-20">{translation}</div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <TextWithTooltips
                  className="w-full text-right"
                  description={props.message.text}
                  tooltips={tooltips}
                />
              )
            ) : (
              <PlayProgress messageId={props.message.id} />
            )}
          </>
        )}
      </MessageText>

      {props.message.is_ai && !props.history && (
        <MessageButtons message={props.message} />
      )}
    </div>
  )
}

const MessageListContainer = classed.div(
  'relative mt-auto flex w-full flex-col gap-12',
)

function MessageList() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const bottomRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }, [
    state.messages.length,
    state.loading,
    state.lastMessage.reply_hint,
    state.lastMessage.showHint,
  ])
  useMount(() => {
    bottomRef.current?.scrollIntoView()
  })
  return (
    <div className="flex w-full flex-1 overflow-y-auto bg-white px-16 pt-40 [scrollbar-width:thin]">
      <MessageListContainer>
        {state.messages.map((x) => {
          const content = x.content && (
            <MessageContentView
              completed={state.lastMessageId != x.id}
              content={x.content}
            />
          )
          const contentAfter = isExercise(x.content) || isImage(x.content)
          return (
            <Fragment key={x.id}>
              {!contentAfter && content}
              <MessageView
                showText={state.showMessageTexts}
                key={x.index}
                message={x}
                loading={false}
              />
              {contentAfter && content}
            </Fragment>
          )
        })}
        {state.loadingMessage ? (
          <MessageView
            showText={state.showMessageTexts}
            key="loading"
            message={state.loadingMessage}
            loading={true}
          />
        ) : (
          <MessageHint />
        )}
        <LessonIsCompletedBlock />
        <div ref={bottomRef}></div>
      </MessageListContainer>
    </div>
  )
}

function MessageHint() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const lastMessage = getLast(state.messages)
  if (
    state.completed ||
    isExercise(state.lastMessage.content) ||
    !lastMessage
  ) {
    return null
  }
  const loading = lastMessage.showHint && !lastMessage.reply_hint
  const title = (
    <>
      {loading ? <Spinner /> : <Icon iconName="bulb" />}
      <FormattedMessage id="chat.hint" />
    </>
  )
  if (lastMessage.reply_hint && lastMessage.showHint) {
    return (
      <div className="relative ml-auto max-w-300 rounded-l-12 rounded-tr-12 bg-warning p-16 text-14 font-semibold">
        <div className="mb-8 flex items-center gap-4 text-16 font-bold text-warning-dark">
          {title}
        </div>
        <div>
          <ClickableWordsWithPopover
            disabled={false}
            selectedWords={state.selectedWords}
            onClick={(word, add) => {
              void store.handleWord(word, add)
            }}
          >
            {lastMessage.reply_hint.text}
          </ClickableWordsWithPopover>
        </div>
        <div className="mt-12 text-gray-dark">
          {lastMessage.reply_hint.translation}
        </div>

        <div className="absolute bottom-4 right-4 flex items-center gap-8">
          <PlayButton hint={true} messageId={lastMessage.id} />
        </div>
      </div>
    )
  }
  return (
    <Button
      onClick={() => {
        void store.showMessageHint()
      }}
      bg="custom"
      rounded="full"
      className="ml-auto w-fit gap-4 bg-white text-warning-dark shadow-button"
      size="little"
    >
      {title}
    </Button>
  )
}

const LessonIsCompleted = () => {
  const navigate = useNavigate()
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  return (
    <div className="my-48 flex flex-col items-center">
      <LessonCompleteImage />
      <div className="my-20 text-24 font-bold">
        <FormattedMessage id="Lesson finished successfully!" />
      </div>

      <Button
        rounded="full"
        bg="blue-gradient"
        onClick={() => {
          const url = state.isDemo
            ? urls.onboarding('generation')
            : urls.program
          navigate(url)
        }}
      >
        <FormattedMessage
          id={state.isDemo ? 'Continue' : 'Return to Learning profile'}
        />
      </Button>
    </div>
  )
}

const LessonIsCompletedBlock = React.memo(() => {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const navigate = useNavigate()
  if (state.completed) {
    return (
      <>
        <LessonIsCompleted />
        {!state.isDemo && (
          <RateLesson
            className="mx-auto"
            onSubmit={(x) => {
              void store.rateChat(
                x.suitableLevel,
                x.experienceRating,
                x.additionalFeedback,
              )
              navigate(urls.program)
            }}
          />
        )}
      </>
    )
  }
  return null
})

LessonIsCompletedBlock.displayName = 'ContentView'

const MessageContentContainer = classed.div('w-fit rounded-12 bg-white', {
  variants: {
    image: {
      true: 'overflow-hidden',
      false: 'p-16',
    },
  },
})

function MessageContentView(props: {
  content: MessageContent
  completed: boolean
}) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const hasImage = isImage(props.content)
  const content = match(props.content)
    .with({ image: P._ }, (x) => (
      <ImageHideBeforeLoad className="max-h-[40vh]" src={x.image} />
    ))
    .with({ text: P._ }, (x) => {
      return (
        <div className="whitespace-pre-wrap">
          <ClickableWordsWithPopover
            animateSpeed="fast"
            disabled={false}
            selectedWords={state.selectedWords}
            onClick={(word, add) => {
              void store.handleWord(word, add)
            }}
          >
            {x.text}
          </ClickableWordsWithPopover>
        </div>
      )
    })
    .with({ fillWords: P._ }, (x) => (
      <FillWords
        completed={props.completed}
        texts={x.fillWords}
        onComplete={(x) => {
          store.onExerciseComplete(x)
        }}
      />
    ))
    .with({ matchWords: P._ }, (x) => (
      <MatchWords
        completed={props.completed}
        words={x.matchWords}
        onComplete={(x) => {
          store.onExerciseComplete(x)
        }}
      />
    ))
    .with({ writeTheGaps: P._ }, (x) => (
      <WriteTheGapss
        completed={props.completed}
        texts={x.writeTheGaps}
        onComplete={(x) => {
          store.onExerciseComplete(x)
        }}
      />
    ))
    .with({ fillTheGaps: P._ }, (x) => (
      <FillTheGaps
        completed={props.completed}
        texts={x.fillTheGaps}
        onComplete={(x) => {
          store.onExerciseComplete(x)
        }}
      />
    ))
    .exhaustive()
  if (isExercise(props.content) && 'fillTheGaps' in props.content) {
    return content
  }
  return (
    <ErrorBoundary
      fallback={<span className="text-alert">Content error!</span>}
    >
      <MessageContentContainer image={hasImage}>
        {content}
      </MessageContentContainer>
    </ErrorBoundary>
  )
}

export const VocabularyHeader = React.memo((props: { className?: string }) => {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const [newWords, topicWords] = splitBy(
    state.vocabulary,
    (x) => x.is_user_added,
  )

  return (
    <div className={cn('mb-16 gap-24 text-14 font-extrabold', props.className)}>
      {!state.isDemo && (
        <div className="flex items-center gap-8">
          <Badge size="small" bg="orange">
            {topicWords.length}
          </Badge>
          <FormattedMessage id="Topic words" />
        </div>
      )}
      <div className="flex items-center gap-8">
        <Badge size="small" bg="purple-light">
          {newWords.length}
        </Badge>
        <FormattedMessage id="My words" />
      </div>
    </div>
  )
})

VocabularyHeader.displayName = 'VocabularyHeader'

interface VocabularyState {
  collapse: Set<number>
}

const CircleDot = (props: { dot: boolean }) => {
  return (
    <div className="flex size-20 items-center justify-center rounded-full border-2 border-main">
      {props.dot && (
        <div className="size-12 rounded-full bg-main animate-in fade-in"></div>
      )}
    </div>
  )
}

const WordBlock = React.memo(
  (props: {
    vocabulary: Vocabulary
    state: VocabularyState
    opened: boolean
    index: number
  }) => {
    const x = props.vocabulary
    const opened = props.opened
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={() => {
          props.state.collapse = toggleSet(props.state.collapse, props.index)
        }}
        className={cn(
          'flex w-full cursor-pointer gap-8 overflow-hidden rounded-12 bg-gray-light p-16',
        )}
      >
        <div className="flex h-24 items-center">
          <CircleDot dot={opened} />
        </div>
        <WordBlockContent opened={opened} word={x} />
      </motion.div>
    )
  },
)
WordBlock.displayName = 'WordBlock'
export function VocabularyView() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const intl = useIntl()
  const stateProxy = useLazyRef(() =>
    proxy<VocabularyState>({
      collapse: new Set(range(store.state.vocabulary.length)),
    }),
  ).current
  const localState = useSnapshot(stateProxy)

  useEffect(() => {
    stateProxy.collapse = new Set(range(state.vocabulary.length - 1))
  }, [state.vocabulary.length, stateProxy])
  const textId = state.isDemo
    ? 'vocabularyPlaceholderDemo'
    : 'vocabularyPlaceholder'
  const description = state.vocabulary.length == 0 && (
    <div>
      <FormattedMessage id={textId} />
    </div>
  )

  const blocks = (words: Vocabulary[]) => (
    <>
      {words.map((x, i) => {
        const opened = !localState.collapse.has(i)
        return (
          <WordBlock
            opened={opened}
            key={i}
            vocabulary={x}
            state={stateProxy}
            index={i}
          />
        )
      })}
    </>
  )

  const [myWords, topicWords] = splitBy(
    state.vocabulary,
    (x) => x.is_user_added,
  )
  const header = (text: string) => (
    <div className="pl-12 text-16 font-bold text-light">{text}</div>
  )

  return (
    <VocabularyContainer>
      <div className="flex flex-col">
        {state.vocabulary.length == 0 ? (
          description
        ) : (
          <>
            <div className="mb-24 flex flex-col gap-8">
              {header(
                `${intl.formatMessage({ id: 'Topic words' })} (${
                  topicWords.length
                })`,
              )}
              {blocks(topicWords)}
            </div>
            <div className="flex flex-col gap-8">
              {header(
                `${intl.formatMessage({ id: 'My words' })}  (${
                  myWords.length
                })`,
              )}
              {blocks(myWords)}
            </div>
          </>
        )}
      </div>
    </VocabularyContainer>
  )
}

export function VocabularyColumn() {
  const state = useSnapshot(useStores().chatStore.state)
  return (
    <div className="hidden size-full w-400 flex-col gap-24 pb-60 sm:flex">
      <div className="overflow-y-auto rounded-6 [scrollbar-width:thin] sm:shadow-card">
        <VocabularyView />
      </div>
      {isWebDomain && <BuyTrial vertical={true} />}

      {state.isDemo && <DemoChoosePlan />}
    </div>
  )
}

function WordBlockContent(props: { opened: boolean; word: Vocabulary }) {
  const store = useStores().chatStore
  const [showTranslation, setShowTranslation] = useState(false)
  return (
    <div>
      <div className="flex items-center gap-8 text-16 leading-6">
        <span className="text-16 font-bold">{props.word.term_text}</span>
        {props.opened && props.word.term_explain && (
          <>
            <Icon
              size="sm"
              iconName="play"
              className={cn('cursor-pointer animate-in fade-in')}
              onClick={(e) => {
                e.stopPropagation()
                store.playWord(props.word.term_text)
              }}
            />
            <Icon
              size="sm"
              iconName="delete"
              className={cn(
                'ml-auto cursor-pointer text-light animate-in fade-in',
              )}
              onClick={(e) => {
                e.stopPropagation()
                void store.removeWord(props.word.id)
              }}
            />
          </>
        )}
      </div>
      <AnimatePresence>
        {props.opened && (
          <motion.div
            className="overflow-hidden"
            initial={{ opacity: 0, height: '0' }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: '0' }}
          >
            {props.word.term_explain ? (
              <>
                <div className="mt-8 overflow-hidden">
                  <div>{props.word.term_explain}</div>
                </div>
                <div className="mt-12">
                  {showTranslation ? (
                    <div>
                      <div className="text-14 font-extrabold text-light">
                        Translation:
                      </div>
                      <div className="text-16 font-bold">
                        {props.word.term_translation}
                      </div>
                    </div>
                  ) : (
                    <Button
                      bg="main"
                      size="little"
                      rounded="full"
                      className="mb-8 uppercase"
                      onClick={(e) => {
                        e.stopPropagation()
                        setShowTranslation(true)
                      }}
                    >
                      TRANSLATE
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <Spinner size="small" className="my-8" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const ChatViewContainer = classed.div(
  'relative mx-auto flex w-full flex-col sm:max-w-800 ',
  {
    variants: {
      loading: {
        true: 'items-center justify-center',
      },
    },
  },
)

export function ChatView(props: { className?: string }) {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const isLoading = state.loading == 'chat'
  return (
    <ChatViewContainer className={props.className} loading={isLoading}>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <>
          {state.showChatBlock && (
            <>
              <MessageList />
              <Footer />
            </>
          )}
        </>
      )}
      <AnswerStatusDrawer />
    </ChatViewContainer>
  )
}

const states = [
  'idle',
  'recording',
  'cancel',
  'loading_start',
  'loading',
  'loading_to_idle',
  'disabled',
  'disabled_to_idle',
] as const

function RecordButtonIcon() {
  const store = useStores().chatStore
  const recordStore = useStores().recordStore
  const state = useSnapshot(store.state)
  const recordState = useSnapshot(recordStore.state)

  const animationState = state.chatInputUnavailable
    ? 'disabled'
    : state.sendButtonDisabled
    ? 'loading_start'
    : recordState.recording
    ? 'recording'
    : 'idle'

  const { rive, RiveComponent } = useRive({
    src: '/animations/microphone.riv',
    stateMachines: 'State Machine 1',
    animations: 'idle',
    autoplay: true,
  })
  const stateMachineInput = useStateMachineInput(
    rive,
    'State Machine 1',
    'Number 1',
  )
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (stateMachineInput) {
      const getCurrentState = () => states[Number(stateMachineInput.value)]
      const nextState =
        getCurrentState() == 'recording' && animationState == 'idle'
          ? 'cancel'
          : getCurrentState() == 'disabled'
          ? 'disabled_to_idle'
          : animationState == 'idle' && getCurrentState() == 'loading'
          ? 'loading_to_idle'
          : animationState

      stateMachineInput.value = states.indexOf(nextState)
      function transfer(
        from: (typeof states)[number],
        to: (typeof states)[number],
        delay: number,
        cb = emptyFn,
      ) {
        if (getCurrentState() == from) {
          setTimeout(() => {
            if (stateMachineInput && getCurrentState() == from) {
              stateMachineInput.value = states.indexOf(to)
              cb()
            }
          }, delay)
        }
      }
      transfer('loading_start', 'loading', 1000)
      transfer('disabled_to_idle', 'loading_start', 500, () => {
        transfer('loading_start', 'loading', 1000)
      })
    }
  }, [rive, animationState, stateMachineInput])

  return <RiveComponent />
}

const ChatTextInputContainer = motion(
  classed.div('flex w-full items-center gap-12'),
)

export const ChatTextInput = () => {
  const store = useStores().chatStore
  const state = useSnapshot(store.state, { sync: true })
  const recoding = state.loading == 'record'
  const intl = useIntl()

  const recordStore = useStores().recordStore
  const recordState = useSnapshot(recordStore.state)

  const input = (
    <>
      <AnimatePresence initial={false}>
        {!state.showTextInput && <MicRecordBlock />}
      </AnimatePresence>
      <ChatTextInputContainer
        className={cn(
          'rounded-6 border-2 border-purple-light',
          state.inputDisabled ? 'bg-gray' : 'bg-white',
        )}
      >
        <TextareaAutosize
          value={
            recoding ? formatTimer(recordState.recordTimer) : state.currentText
          }
          data-testid="chat-input"
          placeholder={intl.formatMessage({ id: 'Message' })}
          disabled={state.inputDisabled}
          autoFocus={state.showTextInput}
          autoComplete="off"
          readOnly={recoding}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault()
              void store.sendCurrentText()
            }
          }}
          onChange={(e) => {
            store.setCurrentText(e.currentTarget.value)
          }}
          maxRows={4}
          className={cn(
            'w-full resize-none rounded-6 px-16 py-8 text-20 leading-tight [scrollbar-width:thin]',
            inputClassBase,
            recoding && 'text-right',
          )}
        />
        <SendButton textInput={true} />
      </ChatTextInputContainer>
    </>
  )
  const continueButton = (
    <Button
      size="extralarge"
      className="w-full"
      rounded="full"
      disabled={!state.exerciseResult || state.disabledByLoading}
      bg="blue-gradient"
      onClick={() => {
        store.continueAfterExercise()
      }}
    >
      <FormattedMessage id="Continue" />
    </Button>
  )

  return (
    <div className="relative flex h-[148px] shrink-0 items-center px-16 sm:mx-0">
      {state.isExercise ? continueButton : input}
    </div>
  )
}

function SendButton(props: { textInput: boolean }) {
  const store = useStores().chatStore
  const recordStore = useStores().recordStore
  const state = useSnapshot(store.state, { sync: true })

  const postfix = state.infoBlock
    ? state.infoBlock
    : isImage(getLast(state.messages)?.content)
    ? 'demo_image'
    : ''

  const buttonSendId = state.isDemo && postfix ? 'send_' + postfix : undefined

  const sendButton = (
    <Button
      size={props.textInput ? 'medium' : 'large'}
      rounded={props.textInput ? undefined : 'full'}
      bg="blue-gradient"
      className={cn('mr-4 gap-12', !props.textInput && 'mr-0 w-44 px-0 pr-4')}
      id={buttonSendId}
      disabled={state.sendButtonDisabled}
      onClick={() => {
        if (props.textInput) {
          void store.sendCurrentText()
        } else {
          void recordStore.stopRecording(true)
        }
      }}
    >
      {props.textInput && <FormattedMessage id="Send" />}
      <Icon
        iconName="send"
        className={cn(props.textInput ? '-mr-8' : '-ml-16')}
      />
    </Button>
  )
  const micButton = (
    <button
      id="record-button"
      className="mr-16 text-violet disabled:text-gray-dark"
      disabled={state.sendButtonDisabled}
      onClick={() => {
        store.setShowTextInput(false)
      }}
    >
      <Icon iconName="mic" />
    </button>
  )

  return state.currentText || state.onlyTextInput || !props.textInput
    ? sendButton
    : micButton
}

function Footer() {
  return <ChatTextInput />
}

function SoundwaveBlock() {
  const service = useAppModule().streamService
  const store = useStores().chatStore
  return (
    <Soundwave
      className="-mx-24 -mb-12"
      getData={() => {
        return store.state.loading == 'record'
          ? service.getUpdatedAnalyzerData()
          : new Uint8Array()
      }}
    />
  )
}

const ChatControlButton = classed.button(
  'flex size-44 items-center justify-center rounded-full bg-purple-light2 text-purple5 opacity-60 shadow-control-button outline-none active:shadow-none',
)

export function MicRecordBlock() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const recording = state.loading == 'record'
  const recordStore = useStores().recordStore
  const showMic = !state.completed

  useEffect(() => {
    recordStore.reset()
  }, [showMic, state.showHistory, recordStore])

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      className="absolute inset-x-0 bottom-0 mt-24 bg-white px-24 sm:rounded-t"
    >
      <SoundwaveBlock />
      <div className="flex px-20">
        <div className="flex flex-1 items-center">
          {recording ? (
            <RecordTimer />
          ) : (
            <ChatControlButton
              onClick={() => {
                store.toggleMute()
              }}
            >
              <Icon iconName={state.mute ? 'mute' : 'unmute'} />
            </ChatControlButton>
          )}
        </div>
        <div className="flex flex-1 justify-center">
          <button
            onClick={() => {
              void recordStore.startOrCancelRecording()
            }}
            disabled={state.sendButtonDisabled}
            className={cn(
              'pointer-events-auto size-100 items-center justify-center outline-none',
            )}
          >
            <RecordButtonIcon />
          </button>
        </div>
        <div className="flex flex-1 items-center justify-end">
          {recording ? (
            <SendButton textInput={false} />
          ) : (
            <ChatControlButton
              onClick={() => {
                store.setShowTextInput(true)
              }}
            >
              <Icon iconName="keyboard" />
            </ChatControlButton>
          )}
        </div>
      </div>
    </motion.div>
  )
}

function RecordTimer() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const recordStore = useStores().recordStore
  const recordState = useSnapshot(recordStore.state)
  const recoding = state.loading == 'record'
  return recoding ? (
    <motion.div
      className="flex items-center gap-8 text-14 font-bold"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="size-12 animate-pulse rounded-full bg-alert-dark"></div>
      {formatTimer(recordState.recordTimer)}
    </motion.div>
  ) : null
}

interface ProgressBarProps {
  value: number
}

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div className="my-12 h-8 w-full rounded-full bg-light">
      <div
        className="h-8 rounded-full bg-main transition-width duration-100 ease-linear"
        style={{ width: props.value + '%' }}
      ></div>
    </div>
  )
}

export function VocabularyDrawer() {
  const store = useStores().chatStore
  const state = useSnapshot(store.state)
  const wordCount = state.vocabulary.length
  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button size="small" rounded="full" bg="blue-gradient">
          {' '}
          {wordCount}{' '}
          <FormattedMessage id="words" values={{ count: wordCount }} />{' '}
        </Button>
      </DrawerTrigger>
      <DrawerContent
        direction="bottom"
        className="mt-24 flex h-auto flex-col rounded-t bg-white"
      >
        <DrawerBar />
        <div className="flex h-[95svh] flex-col">
          <div className="flex items-center justify-between px-16">
            <div className="ml-12 text-20 font-bold">Words</div>
            <DrawerClose asChild>
              <div className="flex size-32 items-center justify-center rounded-full bg-gray">
                <Icon iconName="close" className="text-black" />
              </div>
            </DrawerClose>
          </div>
          <div className="flex-1 overflow-y-auto [scrollbar-width:thin]">
            <VocabularyView />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
