import { useSnapshot } from 'valtio/react'
import {
  InfoKeyWithArrayValue,
  OnboardingInfo,
  OnboardingStore,
} from './OnboardingStore.ts'
import { motion, MotionConfig, useInView } from 'framer-motion'
import { Button } from '../../shared/ui/button/button.tsx'
import { classed } from '@tw-classed/react'
import {
  createStoreContext,
  useIsMobile,
  useStoreContext,
  useTimeout,
} from '../../shared/lib/hooks.ts'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { cn, range, typedIncludes, useMount } from '../../shared/lib/utils.ts'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { Icon } from '../../shared/ui/icon/icon.tsx'
import { preloadAllVideos, Tutorials } from './tutorials.tsx'
import {
  ImageHideBeforeLoad,
  ProgramCreatingImage,
} from '../../shared/ui/images/images.tsx'
import Rive from '@rive-app/react-canvas'
import { Navigate, useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { useAppModule } from '../appContext.ts'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  OnboardingStep,
  onboardingSteps,
} from '../../shared/data/onboardingSteps.ts'
import { ClickableWordsWithPopover } from '../../shared/ui/ClickableWordsWithPopover/ClickableWordsWithPopover.tsx'
import { EdmanAvatar } from '../programs/programView.tsx'
import { ChooseListData } from './onboardingData.ts'
import { DotsAnimation } from '../../shared/ui/spinner.tsx'

export const OnboardingContext = createStoreContext<OnboardingStore>()

export function useOnboardingStore() {
  return useStoreContext(OnboardingContext)
}

export const DurationSelectionStep = () => {
  const items = useOnboardingStore().data.duration
  return (
    <ChooseList
      className="flex flex-wrap gap-8"
      classNameButtons={cn('min-w-300 flex-1 shrink-0 sm:h-80')}
      infoKey="duration"
      items={items}
    />
  )
}

export const ImproveSelectionStep = () => {
  const items = useOnboardingStore().data.improves
  return (
    <ChooseList
      className="flex flex-wrap gap-8"
      classNameButtons={cn('min-w-300 flex-1 shrink-0')}
      infoKey="improves"
      items={items}
    />
  )
}

export const AgeSelectionStep = () => {
  const items = useOnboardingStore().data.age
  return (
    <ChooseList
      className="flex flex-wrap gap-8"
      classNameButtons={cn('min-w-300 flex-1 shrink-0 sm:h-56')}
      infoKey="age"
      items={items}
    />
  )
}

export const SexSelectionStep = () => {
  const items = useOnboardingStore().data.sex
  return (
    <ChooseList
      classNameButtons="flex-1"
      className="flex flex-wrap gap-8 sm:h-100 sm:flex-nowrap"
      infoKey="sex"
      items={items}
    />
  )
}

export const GoalSelectionStep = () => {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)

  const items = useOnboardingStore().data.goal
  const list = (invisible: boolean) => (
    <ChooseList
      className={cn('flex flex-wrap gap-8', invisible && 'invisible')}
      classNameButtons={cn(
        'shrink-0 sm:h-80',
        state.goalNumber && !invisible ? 'w-full sm:w-1/2' : 'min-w-300 flex-1',
      )}
      infoKey="goal"
      showOnlyIndex={
        state.goalNumber && !invisible ? state.goalNumber - 1 : undefined
      }
      items={items}
      onChoose={(i) => {
        store.setGoalNumber((i + 1) as 1 | 2 | 3 | 4 | 5 | 6)
      }}
    />
  )
  return (
    <div className="relative">
      {list(true)}
      <div className="absolute inset-0">
        {list(false)}
        <GoalAnswerBlock />
      </div>
    </div>
  )
}

export const ProfessionSelectionStep = () => {
  const items = useOnboardingStore().data.profession
  return (
    <ChooseList
      className="flex flex-wrap gap-8 sm:gap-12"
      infoKey="profession"
      items={items}
    />
  )
}

export const InterestSelectionStep = () => {
  const items = useOnboardingStore().data.interests
  return (
    <ChooseList
      className="flex flex-wrap gap-8 sm:gap-12"
      infoKey="interests"
      items={items}
    />
  )
}

export const LanguageLevelSelectionStep = () => {
  const store = useOnboardingStore()

  const items = useOnboardingStore().data.languageLevel
  const levels = useOnboardingStore().data.levels
  return (
    <ChooseList
      className="flex flex-wrap gap-4 sm:gap-8"
      classNameButtons={cn('h-44 sm:h-auto')}
      infoKey="languageLevel"
      items={items}
      onChoose={(i) => {
        store.setLevel(levels[i])
      }}
    />
  )
}

function ChooseList(props: {
  infoKey: keyof OnboardingInfo
  className: string
  classNameButtons?: string
  showOnlyIndex?: number
  items: ChooseListData
  onChoose?: (index: number) => void
}) {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const smallSize = new Set<keyof OnboardingInfo>([
    'improves',
    'profession',
    'interests',
    'languageLevel',
  ])
  const otherInputRef = useRef<HTMLInputElement>(null)
  const editOther = state.editOther
  const info = state.info
  const editable = ['none']
  const canBeEdit = editable.includes(props.infoKey)
  return (
    <div className={cn('', props.className)}>
      {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
      {props.items.map((item, i) => {
        const active = typedIncludes(InfoKeyWithArrayValue, props.infoKey)
          ? info[props.infoKey].includes(item[2])
          : item[2] == info[props.infoKey]
        const last = i == props.items.length - 1
        return (
          <ChooseButton
            id={
              props.infoKey === 'languageLevel'
                ? `onboarding.languageLevel-${i}`
                : undefined
            }
            className={cn(
              props.showOnlyIndex !== undefined &&
                (i !== props.showOnlyIndex ? 'hidden' : ''),
              props.classNameButtons,
            )}
            size={smallSize.has(props.infoKey) ? 'small' : 'large'}
            active={active}
            key={item[2]}
            onClick={() => {
              props.onChoose?.(i)
              if (last && canBeEdit) {
                store.setEditOther(true)
              } else {
                store.setInfo(props.infoKey, item[2])
                const noAutoChangeStep: (keyof OnboardingInfo)[] = [
                  'interests',
                  'improves',
                  'goal',
                ]
                if (!noAutoChangeStep.includes(props.infoKey)) {
                  store.changeStep(true)
                }
              }
            }}
          >
            <div className="flex items-center justify-center gap-8">
              {item[0] && <span className="text-20 sm:text-24">{item[0]}</span>}
              {editOther && last ? (
                <div className="flex gap-8">
                  <input
                    ref={otherInputRef}
                    className="border-b bg-transparent"
                    maxLength={30}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        store.updateOther(props.infoKey, e.currentTarget.value)
                      }
                    }}
                  />
                  <Button
                    size="small"
                    bg="main"
                    onClick={(e) => {
                      if (otherInputRef.current) {
                        e.stopPropagation()
                        store.updateOther(
                          props.infoKey,
                          otherInputRef.current.value,
                        )
                      }
                    }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div className="whitespace-pre-wrap text-left">{item[1]}</div>
              )}
            </div>
          </ChooseButton>
        )
      })}
    </div>
  )
}

const EdmanTextBubble = classed.div(
  'size-fit min-w-76 rounded-r-[26px] rounded-bl-[26px] bg-white p-12 text-18 font-semibold leading-tight sm:rounded-[38px] sm:leading-normal',
  {
    variants: {
      profile: {
        true: 'sm:p-24',
        false: 'sm:px-24',
      },
    },
    defaultVariants: {
      profile: false,
    },
  },
)

interface EdmanTextBlocksProps {
  typing?: boolean
  onLastDisplayEnd?: () => void
}

function EdmanTextBlocks({
  typing = false,
  onLastDisplayEnd,
}: EdmanTextBlocksProps) {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const data = onboardingSteps[state.currentStep]
  const intl = useIntl()
  const messageKey = data.question
  const text = intl.formatMessage({ id: messageKey }, { name: state.info.name })
  const firstEnter = state.isForward && state.currentStep > state.userLastStep
  const animateSpeed = firstEnter
    ? 'fast'
    : // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (state.stepType == 'goal' && state.goalNumber != undefined) ||
      state.currentStep <= state.userLastStep
    ? undefined
    : 'fast'

  const [isLoading, setIsLoading] = useState(typing)
  const textDisplayDelay = 1000

  useTimeout(() => {
    setIsLoading(false)
  }, textDisplayDelay)

  return (
    <div className="flex flex-col gap-8">
      <EdmanTextBubble>
        {isLoading ? (
          <DotsAnimation />
        ) : (
          <ClickableWordsWithPopover
            selectedWords={[]}
            animateSpeed={animateSpeed}
            disabled={false}
            onLastDisplayEnd={onLastDisplayEnd}
          >
            {text}
          </ClickableWordsWithPopover>
        )}
      </EdmanTextBubble>
    </div>
  )
}

export function VideoAvatarBlock(props: {
  text: string
  videoUrl: string
  className?: string
}) {
  return (
    <div
      className={cn(
        'flex max-w-800 flex-col  items-end sm:flex-row sm:justify-end',
        props.className,
      )}
    >
      <div className="z-10 mx-8 sm:mx-0 sm:-mr-44 sm:pb-40">
        <EdmanBlock hideMute text={props.text} />
      </div>
      <EdmanAvatar className="w-200 shrink-0" hemisphere />
    </div>
  )
}

export const NameInputStep = () => {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state, { sync: true })
  const intl = useIntl()

  return (
    <div>
      <TextInput
        type="text"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            store.changeStep(true)
          }
        }}
        name="name"
        value={state.info.name ?? ''}
        size="extralarge"
        className="w-full rounded-full border border-purple-light2 placeholder:text-18 placeholder:font-bold placeholder:text-purple3"
        placeholder={intl.formatMessage({ id: 'onboarding.enterName' }) + '...'}
        autoComplete="off"
        onChange={(e) => {
          store.setInfo('name', e.target.value)
        }}
      />
    </div>
  )
}

export function OnboardingBlock(props: {
  title: string
  text: string
  goal?: boolean
}) {
  return (
    <motion.div
      transition={{
        duration: 0.3,
        type: 'tween',
        ease: 'easeInOut',
      }}
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0 }}
      className={cn('flex items-center gap-16 rounded-4 bg-purple3 p-16')}
    >
      {props.goal && <div className="-m-12 text-[80px]">🎯</div>}
      <div>
        <div className="text-14 font-semibold text-light">{props.title}</div>
        <div className="text-20 font-bold">{props.text}</div>
      </div>
    </motion.div>
  )
}

export const ChooseButtonContainer = classed.button(
  'flex items-center justify-center transition-all hover:opacity-80',
  {
    variants: {
      size: {
        small:
          'rounded-full px-12 py-0 text-12 font-bold sm:px-16 sm:py-4 sm:text-16',
        large: 'rounded-32 px-24 py-8 text-12 font-extrabold sm:text-18',
      },
      active: {
        false: 'bg-white text-default',
        true: 'bg-user-message text-black',
      },
    },
  },
)

function ChooseButton(props: {
  onClick: () => void
  id?: string
  size?: 'small' | 'large'
  children: React.ReactNode
  className?: string
  active: boolean
}) {
  return (
    <ChooseButtonContainer
      id={props.id}
      size={props.size ?? 'large'}
      active={props.active}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </ChooseButtonContainer>
  )
}

export function Progress() {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const stages = onboardingSteps
  const navigate = useNavigate()

  return (
    <div className="my-16 flex w-full items-center justify-center gap-16 px-8 sm:mt-40">
      {!state.hideBackButton && (
        <button
          id="onboarding.mobile-back"
          onClick={() => {
            navigate(-1)
          }}
          className="size-32 shrink-0 p-4 pl-[3px] sm:hidden"
        >
          <Icon iconName="left" className="text-black" />
        </button>
      )}
      <ProgressSteps step={state.currentStep + 1} total={stages.length - 1} />
      <div className="w-20"></div>
    </div>
  )
}

export const ProgressSteps = (props: { step: number; total: number }) => {
  return (
    <div className="flex h-4 w-full max-w-600 gap-4">
      {range(props.total).map((i) => (
        <div
          key={i}
          className={cn(
            'flex-1 rounded-full bg-main transition-opacity',
            i >= props.step && 'opacity-20',
          )}
        ></div>
      ))}
    </div>
  )
}

export const StepContainer = classed.div(
  'flex min-h-0 w-full flex-1 flex-col gap-16 overflow-y-auto overflow-x-hidden pb-16 sm:pb-0',
  {
    variants: {
      chat: {
        false: 'items-center justify-center px-12 sm:px-0',
      },
    },
  },
)

export function OnboardingView() {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const data = onboardingSteps[state.currentStep]
  const authStore = useAppModule().authStore
  const isMobile = useIsMobile()
  const stepsWithoutNextButton: OnboardingStep[] = [
    'sex',
    'age',
    'profession',
    'level',
    'duration',
  ]

  useMount(() => {
    void preloadAllVideos()
  })

  if (
    authStore.isLogin() &&
    authStore.isConfirm() &&
    !authStore.state.isAnon &&
    state.completed
  ) {
    return <Navigate to={urls.learningProfile()} />
  }

  if (state.goToDemo) {
    return <Navigate to={urls.demo()} />
  }

  if (state.completed) {
    authStore.trackEvent('finish_onboarding')
    const nextUrl = state.hasSubscription ? urls.program : urls.subscriptions
    return <Navigate to={nextUrl} />
  }

  return (
    <>
      <MotionConfig
        transition={{
          duration: 0.5,
          type: 'tween',
          ease: 'easeInOut',
        }}
      >
        <div
          className={cn(
            'relative flex w-full flex-col items-center bg-light text-black transition-colors',
          )}
        >
          {data.step != 'program' && data.step != 'askdemo' && <Progress />}
          <StepContainer chat={false}>
            {match(data.step)
              .with('generation', () => <CreatingProgram />)
              .with('program', () => <ProgramStep />)
              .otherwise(() => (
                <>
                  <StepView />
                  {isMobile && (
                    <NavigationButtons
                      hideNext={stepsWithoutNextButton.includes(state.stepType)}
                    />
                  )}
                </>
              ))}
          </StepContainer>
        </div>
      </MotionConfig>
    </>
  )
}

export function TutorialsStep() {
  return (
    <div className="sm:-ml-100">
      <Tutorials showTooltips={true} />
      <NavigationButtons className="sm:pl-200" />
    </div>
  )
}

export function EdmanBlock(props: {
  text: string
  left?: boolean
  hideAvatar?: boolean
  hideMute?: boolean
  className?: string
  fast?: boolean
}) {
  const appStore = useAppModule().appStore
  const appState = useSnapshot(appStore.state)
  const ref = useRef<HTMLDivElement>(null)
  const [animate, setAnimate] = useState(false)
  const isInView = useInView(ref)
  useEffect(() => {
    if (isInView) {
      setAnimate(true)
    }
  }, [isInView])
  return (
    <motion.div
      ref={ref}
      transition={{
        delay: 0.2,
        duration: 0.3,
        type: 'tween',
        ease: 'easeInOut',
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.5 }}
      className={cn(
        'relative max-w-fit whitespace-pre-wrap bg-purple-light2 px-24 pb-4 pt-8 text-20 font-bold leading-[1.2em] text-default',
        props.left ? 'rounded-r rounded-tl' : 'rounded-l rounded-tr',
        props.className,
      )}
    >
      {!props.hideMute && (
        <button
          onClick={() => {
            appStore.toggleEdmanUnmute()
          }}
          className={cn(
            'absolute top-0 rounded-8 bg-white p-4',
            props.left ? '-right-36' : '-left-36',
          )}
        >
          <Icon iconName={appState.edmanUnmute ? 'unmute' : 'mute'} />
        </button>
      )}
      <div className="text-14 font-extrabold text-main-light">Edman</div>
      <ClickableWordsWithPopover
        disabled={!animate}
        selectedWords={[]}
        delay={300}
        animateSpeed={props.fast ? 'fast' : 'slow'}
      >
        {props.text}
      </ClickableWordsWithPopover>
    </motion.div>
  )
}

function NavigationButtons(
  props: { className?: string; hideBack?: boolean; hideNext?: boolean } = {},
) {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const hideBack = state.hideBackButton
  const isAskDemo = state.stepType == 'askdemo'

  const nextButtonId = 'onboarding-next-' + state.stepType
  const continueText = isAskDemo ? (
    'Начать демо-урок!'
  ) : (
    <FormattedMessage
      id={
        state.stepType == 'name' && !state.info.name
          ? 'Continue without name'
          : 'Continue'
      }
    />
  )
  const prevButton = (
    <Button
      disabled={!hideBack && !state.canGoPrev}
      rounded="full"
      bg="gray-gradient"
      size="extralarge"
      className={cn('relative hidden sm:block', hideBack && 'invisible')}
      onClick={() => {
        store.changeStep(false)
      }}
    >
      <div className="absolute inset-y-0 left-12 flex rotate-180 items-center">
        <Icon size="large" iconName="arrowRight" />
      </div>
      <div className="ml-16">
        <FormattedMessage id="Back" />
      </div>
    </Button>
  )

  const nextButton = (
    <Button
      disabled={!state.canGoNext}
      id={nextButtonId}
      rounded="full"
      bg="blue-gradient"
      size="extralarge"
      className={cn('relative w-full sm:w-auto', props.hideNext && 'hidden')}
      onClick={() => {
        store.changeStep(true)
      }}
    >
      <div className="mr-16">{continueText}</div>
      <div className="absolute inset-y-0 right-12 flex items-center">
        <Icon size="large" iconName="arrowRight" />
      </div>
    </Button>
  )

  const skipButton = (
    <Button
      rounded="full"
      bg="gray-gradient"
      size="extralarge"
      id="skip-demo"
      onClick={() => {
        store.skipDemo()
      }}
    >
      Пропустить
    </Button>
  )

  if (isAskDemo) {
    return (
      <div className="flex flex-col justify-between gap-12 sm:flex-row">
        {skipButton}
        {nextButton}
      </div>
    )
  }

  return (
    <div
      className={cn(
        'mt-auto flex w-full justify-between gap-16 sm:mt-16',
        props.className,
      )}
    >
      {prevButton}
      {nextButton}
    </div>
  )
}

function CreatingProgram() {
  return (
    <div className="flex flex-col items-center">
      <ProgramCreatingImage />
      <div className="mb-24 mt-48 text-center text-32 font-semibold">
        <FormattedMessage id="Please wait a little..." />
      </div>
      <div className="mb-16 max-w-400 font-semibold">
        <FormattedMessage id="Edman is generating" />
      </div>
      <Rive className="size-100" src="/animations/generation.riv" />
    </div>
  )
}

function GoalAnswerBlock() {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const intl = useIntl()

  const answerKey =
    state.goalNumber == undefined || state.stepType != 'goal'
      ? undefined
      : (`onboarding.answer-${state.goalNumber}` as const)
  return (
    answerKey && (
      <EdmanTextBubble className="mt-16">
        <ClickableWordsWithPopover
          selectedWords={[]}
          delay={500}
          animateSpeed="fast"
          disabled={false}
        >
          {intl.formatMessage({ id: answerKey })}
        </ClickableWordsWithPopover>
      </EdmanTextBubble>
    )
  )
}

function ProgramStepBlock(props: { title: string; items: React.ReactNode[] }) {
  return (
    <EdmanTextBubble profile={true}>
      <div className="mb-12 text-18 font-bold">{props.title}</div>
      {props.items.map((x, i) => (
        <div
          key={i}
          className="flex items-center gap-8 border-t-2 border-gray-light py-8"
        >
          {x}
        </div>
      ))}
    </EdmanTextBubble>
  )
}

function ProgramStep() {
  const profileData = useOnboardingStore().getProfileData()
  const content = [
    '250+ основных урока по 12 темам',
    'Занятия на лексику, грамматику, говорение, аудирование, фонетику',
    'Обсуждение жизненных ситуаций из вашего опыта',
    'Бесконечное общение с искусственным интеллектом в различных ролях',
    'Работа над ошибками и практика',
  ]
  return (
    <div>
      <div className="relative mx-auto my-24 w-fit text-center text-28 font-extrabold leading-tight sm:text-36">
        <img
          className="absolute -left-40 sm:-top-20"
          src="/images/onboarding/program-left.svg"
        />
        Ваш личный <br className="sm:hidden" />{' '}
        <span className="text-main">план обучения</span> готов
        <img
          className="absolute -right-40 top-0 sm:-top-20"
          src="/images/onboarding/program-right.svg"
        />
      </div>
      <StepDataContainer className="mb-16">
        <div className="flex flex-col gap-16 sm:flex-row sm:gap-24">
          <ProgramStepBlock
            title="Ваш профиль:"
            items={profileData.map(([icon, value]) => (
              <>
                <div className="text-20">{icon}</div>
                <div className="text-14 font-semibold">{value}</div>
              </>
            ))}
          />
          <ProgramStepBlock
            title="Вы получите:"
            items={content.map((x) => (
              <>
                <div className="text-20">
                  <Icon iconName="done" />
                </div>
                <div className="text-14 font-semibold">{x}</div>
              </>
            ))}
          />
        </div>
      </StepDataContainer>
      <NavigationButtons />
    </div>
  )
}

function AskDemoStep() {
  return (
    <div>
      <ImageHideBeforeLoad
        className="mx-auto mt-36 flex h-full flex-col justify-between"
        src="/images/onboarding/rocket.png"
      />
    </div>
  )
}

export function StepView() {
  const store = useOnboardingStore()
  const state = useSnapshot(store.state)
  const [isLoading, setIsLoading] = useState(true)
  const stepsWithoutNextButton: OnboardingStep[] = [
    'sex',
    'age',
    'profession',
    'level',
    'duration',
  ]

  const stepComponents: Record<OnboardingStep, React.ReactNode> = {
    hello: <></>,
    name: <NameInputStep />,
    age: <AgeSelectionStep />,
    interests: <InterestSelectionStep />,
    profession: <ProfessionSelectionStep />,
    level: <LanguageLevelSelectionStep />,
    goal: <GoalSelectionStep />,
    improve: <ImproveSelectionStep />,
    duration: <DurationSelectionStep />,
    sex: <SexSelectionStep />,
    generation: <></>,
    program: <></>,
    askdemo: <AskDemoStep />,
  }
  const steps = onboardingSteps
  const step = steps[state.currentStep]
  const isMobile = useIsMobile()

  const handleLastDisplayEnd = () => {
    setIsLoading(false)
    store.updateUserLastStep()
  }

  useLayoutEffect(() => {
    setIsLoading(true)
  }, [step])

  const firstView = state.currentStep > state.userLastStep
  const firstEnter = state.isForward && firstView
  const needAnimate = firstEnter || state.currentStep <= state.userLastStep

  return (
    <motion.div
      className="my-auto flex w-full max-w-[860px] flex-col"
      key={state.stepType + state.goalNumber}
      transition={{ duration: 0.3, ease: 'easeOut' }}
    >
      <div className="mb-32 text-center text-32 font-extrabold">
        {step.title}
      </div>
      <StepDataContainer key={state.currentStep}>
        <EdmanTextBlocks
          onLastDisplayEnd={handleLastDisplayEnd}
          typing={firstView}
        />
        {needAnimate ? (
          <motion.div
            className="mt-32"
            initial={{
              opacity: state.currentStep > state.userLastStep ? 0 : 1,
            }}
            animate={{
              opacity:
                isLoading && state.currentStep > state.userLastStep ? 0 : 1,
            }}
            transition={{ duration: 0.2, delay: 0.2 }}
          >
            {stepComponents[state.stepType]}
          </motion.div>
        ) : (
          <div className="mt-24">{stepComponents[state.stepType]}</div>
        )}
      </StepDataContainer>
      {!isMobile && (
        <NavigationButtons
          hideNext={stepsWithoutNextButton.includes(state.stepType)}
        />
      )}
    </motion.div>
  )
}

const StepDataContainer = classed.div('mb-40')
