export const moduleInfos = [
  {
    title: 'Back to Basics',
    desc: 'Build your confidence as you master vocabulary and grammar',
    level: 'A2',
    topics: [
      'Basic Communication Skills',
      'Leisure activities',
      'Everyday Routine',
      'Weekend Activities',
      'Nearest and Dearest',
      'Family Members',
      'Describing Family',
      'Family Conflicts',
      'There is no Place Like Home',
      'Types of Housing',
      'Out of the city',
      'Big City life',
    ],
    topicsYear: [
      'Basic Communication Skills',
      'Nearest and Dearest',
      'There is no Place Like Home',
      'Finding Joy in Hobbies',
      'Tales of Transport and Adventure',
      "A Shopper's Guide",
      'Find Your Job',
      'After the Storm',
      'Exploring the Animal Kingdom',
      'An Apple a Day: Secrets to Health',
    ],
  },
  {
    title: 'Step up',
    desc: 'Sharpen your language skills and boost your confidence in a corporate setting',
    level: 'B1',
    topics: [
      'Mastering Daily Dialogues',
      'Meet and Greet',
      'Hobbies and Interests',
      'Eating out',
      'Climbing the Career Ladder',
      'Job Interview',
      'Networking',
      'Workplace Challenges',
      'Beyond the Beaten Path',
      "The Traveler's Toolkit",
      'Cultural Differences',
      'Travel Problems',
    ],
    topicsYear: [
      'Mastering Daily Dialogues',
      'Climbing the Career Ladder',
      'Beyond the Beaten Path',
      'Keys to a Healthier You',
      'Cultural Mosaic',
      'Media Mirror',
      'Tech Horizons',
      'Becoming Your Best',
      'Celebrations and Traditions',
      'Culinary Journeys',
    ],
  },
  {
    title: 'Dream Big and Aim High',
    desc: 'Dive into professional scenarios that challenge your thinking',
    level: 'B2',
    topics: [
      'Green Dialogues',
      'Climate Change',
      'Global Health Issues',
      'How Eco-guilty Are You',
      'Urban Odyssey',
      'Risky trips',
      'Are you a Risk Taker?',
      'Survivors’s Club',
      'Career Crossroads',
      'Cultural Differences',
      'Career Paths',
      'Office Work VS. Remote Work',
    ],
    topicsYear: [
      'Perfecting the Art of Expression',
      'Green Dialogues',
      'Balancing Crime, Punishment, and Social Values',
      'Entrepreneurship and Innovation',
      'Urban Odyssey',
      'Mind Matters',
      'Navigating Health Across the Ages',
      'Wealth Building and Argument Tactics',
      'Creative Wisdom',
      'Career Crossroads',
    ],
  },
  {
    title: 'Launchpad to Success',
    desc: 'Participate in high-level discussions, and refine your professional jargon across various industries',
    level: 'B2+',
    topics: [],
    topicsYear: [],
  },
  {
    title: 'Literary Adventure',
    desc: 'Enrich your language skills through the captivating world of fiction reading',
    level: 'B2++',
    topics: [],
    topicsYear: [],
  },
]

export function getModuleInfoByLevel(level: string) {
  const info = moduleInfos.find((x) => x.level === level)
  if (!info) {
    throw new Error('Module info not found for ' + level)
  }
  return info
}
