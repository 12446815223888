import { FullChat, FullChatContainer } from '../widgets/chat/fullChat.tsx'
import { demoLessonTag } from '../shared/api/chatApi.ts'
import { useMount } from '../shared/lib/utils.ts'
import { useAppModule } from '../features/appContext.ts'
import { useSnapshot } from 'valtio/react'
import { Spinner } from '../shared/ui/spinner.tsx'
import React from 'react'
import { isNoDemoDomain, urls } from '../shared/urls.ts'
import { Navigate } from 'react-router-dom'
import { useOnboardingStore } from '../features/onboarding/OnboardingView.tsx'

export const DemoPage = React.memo(() => {
  const authStore = useAppModule().authStore
  const chatApi = useAppModule().chatsApi
  const authState = useSnapshot(authStore.state)
  const appState = useSnapshot(useAppModule().appStore.state)
  const onboardingStore = useOnboardingStore()
  useMount(() => {
    onboardingStore.resetGoToDemo()
    if (!authStore.isLogin()) {
      void authStore.registerAnon('A2').then(() => {
        return chatApi.definePrograms()
      })
    }
    authStore.trackMetrika('demo_lesson_start')
  })

  if (isNoDemoDomain) {
    const url = authState.isLogin ? urls.index : urls.subscriptions
    return <Navigate to={url} />
  }

  return authState.isLogin ? (
    <FullChat key={appState.language} tag={demoLessonTag} />
  ) : (
    <FullChatContainer>
      <Spinner className="m-auto" size="large" />
    </FullChatContainer>
  )
})
DemoPage.displayName = 'DemoPage'
