import { proxy } from 'valtio'
import { FillWordText } from './fillWords.tsx'
import { shuffleArray } from '../../shared/lib/utils.ts'

interface State {
  selectedIndex: number
  selectedWords: string[]
  words: string[]
  answers: string[]
}

export class FillWordsStore {
  state: State

  getResult() {
    return this.state.selectedWords
      .map((word, i) => `${i + 1}. ${word}`)
      .join('\n')
  }
  constructor(private texts: readonly FillWordText[]) {
    const words = texts.flatMap((text) =>
      text
        .filter((x): x is { word: string } => typeof x !== 'string')
        .map((x) => x.word),
    )
    this.state = proxy({
      selectedWords: [],
      answers: shuffleArray(words),
      words,
      selectedIndex: 0,
    })
  }

  setCurrentIndex(index: number) {
    this.state.selectedIndex = index
  }

  selectWord(word: string) {
    if (this.state.selectedIndex >= this.state.words.length) {
      return
    }
    this.state.selectedWords[this.state.selectedIndex] = word
    this.state.selectedIndex += 1
  }
}
