import { useSnapshot } from 'valtio'
import { MatchWordsStore, WordWithMeaning } from './matchWordsStore.ts'
import { useLazyRef } from '../../shared/lib/hooks.ts'
import { cn } from '../../shared/lib/utils.ts'
import { Badge } from '../../shared/ui/card/card.tsx'
import { CompleteButton, ExerciseHeader } from './exerciseComponents.tsx'
import { FormattedMessage } from 'react-intl'

interface MatchWordProps {
  words: readonly WordWithMeaning[]
  completed: boolean
  onComplete: (result: string) => void
}

export const MatchWords = (props: MatchWordProps) => {
  const store = useLazyRef(() => new MatchWordsStore(props.words)).current

  const state = useSnapshot(store.state)

  return (
    <div>
      <ExerciseHeader
        title={<FormattedMessage id="exercise.matchWords.title" />}
        subtitle={<FormattedMessage id="exercise.matchWords.subtitle" />}
      />
      <div className="mb-16 flex gap-24">
        <div className="flex flex-wrap gap-8">
          {state.answers.map((meaning) => (
            <Badge
              bg="violetd"
              rounded={false}
              className="cursor-pointer justify-center"
              size="small"
              key={meaning}
              onClick={() => {
                store.selectMeaning(meaning)
              }}
            >
              {meaning}
            </Badge>
          ))}
        </div>
      </div>

      <div className="flex gap-16">
        <div className="flex min-w-100 flex-col gap-8">
          {props.words.map(({ word }) => (
            <div className="flex gap-16" key={word}>
              <Badge
                rounded={false}
                size="small"
                className="flex-1 justify-center"
                bg="gray"
              >
                {word}
              </Badge>
              <span className="text-violetd">—</span>
            </div>
          ))}
        </div>

        <div className="flex min-w-100 flex-col gap-8">
          {props.words.map((x, i) => {
            const selected = state.selectedMeanings[i]
            return (
              <Badge
                rounded={false}
                size="small"
                className={cn(
                  'cursor-pointer justify-center border-2',
                  i == state.selectedIndex
                    ? 'border-main'
                    : 'border-transparent',
                )}
                onClick={() => {
                  store.setCurrentIndex(i)
                }}
                key={x.word}
                bg={
                  selected
                    ? x.synonym == selected
                      ? 'green-light'
                      : 'alert-light'
                    : 'gray'
                }
              >
                {state.selectedMeanings[i]}
              </Badge>
            )
          })}
        </div>
      </div>
      <CompleteButton
        completed={props.completed}
        onComplete={() => {
          props.onComplete(store.getResult())
        }}
      />
    </div>
  )
}
