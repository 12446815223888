import { proxy } from 'valtio'
import { shuffleArray } from '../../shared/lib/utils.ts'

export interface WordWithMeaning {
  word: string
  synonym: string
}

interface State {
  selectedIndex: number
  selectedMeanings: string[]
  matches: readonly WordWithMeaning[]
  answers: string[]
}

export class MatchWordsStore {
  state: State

  getResult() {
    const prefix =
      'I have completed the "Match words with synonyms" exercise.\n'
    return (
      prefix +
      this.state.matches
        .map((x, i) => `${x.word} - ${this.state.selectedMeanings[i] ?? ''}`)
        .join(', ')
    )
  }

  constructor(matches: readonly WordWithMeaning[]) {
    this.state = proxy<State>({
      matches,
      selectedIndex: 0,
      selectedMeanings: [],
      answers: shuffleArray(matches.map((x) => x.synonym)),
    })
  }

  setCurrentIndex(index: number) {
    this.state.selectedIndex = index
  }

  selectMeaning(meaning: string) {
    this.state.selectedMeanings[this.state.selectedIndex] = meaning
    this.state.selectedIndex += 1
  }
}
