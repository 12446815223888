import { RefObject, useEffect, useState } from 'react'

export type PickPublic<T> = Pick<T, keyof T>
export function nilMap<T, R>(
  value: T | undefined,
  fn: (item: T) => R,
): R | undefined {
  if (value === undefined) {
    return undefined
  }
  return fn(value)
}

export function saveBlob(blob: Blob, name: string) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()
  URL.revokeObjectURL(url)
}

export function delay(ms: number) {
  return new Promise<void>((resolve) =>
    setTimeout(() => {
      resolve(undefined)
    }, ms),
  )
}

export async function waitFor(fn: () => boolean, interval = 100) {
  for (let i = 0; i < 100; i++) {
    if (fn()) {
      return
    }
    await delay(interval)
  }
}

export function getFormObject(form: HTMLFormElement) {
  return Object.fromEntries(new FormData(form).entries()) as Record<
    string,
    string
  >
}

export type ClassValue = string | number | null | boolean | undefined

export function cn(...classes: ClassValue[]) {
  return classes.filter(Boolean).join(' ')
}

export async function playAudio(blob: Blob) {
  const audioUrl = URL.createObjectURL(blob)
  const audio = new Audio(audioUrl)
  await audio.play()
}

export function removeNonLettersAndPunctuation(input: string): string {
  return input.replace(/[^a-zA-Zа-яА-Я0-9\s.,!?;:()']/g, '')
}

export function clearTextForSpeech(input: string): string {
  return removeNonLettersAndPunctuation(removeTextTag(input))
}

export function removeTextTag(input: string): string {
  return input
    .replace(/<text>.*?<\/text>/gs, '')
    .replace(/---.*?---/gs, '')
    .replace(/```.*?```/gs, '')
}

export function getTextTag(input: string): string {
  return (
    input.match(/<text>(.*?)<\/text>/s)?.[1] ??
    input.match(/---(.*?)---/s)?.[1] ??
    input.match(/```(.*?)```/s)?.[1] ??
    ''
  )
}

export function formatTimer(timerMS: number): string {
  const minutes = Math.floor(timerMS / 60000)
  const seconds = Math.floor((timerMS % 60000) / 1000)
  const ms = timerMS % 1000
  return `${minutes}:${String(seconds).padStart(2, '0')},${
    String(ms).padStart(3, '0')[0]
  }`
}

export function removeOnlyTextTag(input: string): string {
  return input.replace(/<\/?text>/g, '')
}

export function randomIndex(max: number) {
  return Math.floor(Math.random() * max)
}
export function randomElement<T>(array: T[]): T {
  return array[randomIndex(array.length)]
}

const maxRetryCount = 5
const maxDelaySec = 30

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message
  } else if (typeof error == 'string') {
    return error
  } else {
    return String(error)
  }
}
export async function retry<T>(fn: () => Promise<T>, retries = 1): Promise<T> {
  try {
    return await fn()
  } catch (e) {
    const error = getErrorMessage(e)
    if (retries <= maxRetryCount) {
      const delay =
        Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000
      await new Promise((resolve) => setTimeout(resolve, delay))
      console.log(
        `Request failed, retrying ${retries}/${maxRetryCount}. Error ${error}`,
      )
      return retry(fn, retries + 1)
    } else {
      throw new Error(`Max retries exceeded. error: ${error}`)
    }
  }
}

export type TimerId = ReturnType<typeof setInterval>

export function useUnmount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fn, [])
}
export function useMount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, [])
}

export const appVersion = APP_VERSION

export function getHash(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}

export function sortBy<T>(arr: readonly T[], fn: (item: T) => number) {
  const newArr = [...arr]
  newArr.sort((a, b) => fn(a) - fn(b))
  return newArr
}

export function useHover<T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>,
): boolean {
  const [value, setValue] = useState(false)

  useEffect(() => {
    const element = elementRef.current
    if (!element) return

    const handleMouseEnter = () => {
      setValue(true)
    }
    const handleMouseLeave = () => {
      setValue(false)
    }

    element.addEventListener('mouseenter', handleMouseEnter)
    element.addEventListener('mouseleave', handleMouseLeave)
    document.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter)
      element.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [elementRef])

  return value
}

export function escapeRegExp(input: string) {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function range(end: number) {
  return Array.from({ length: end }, (_, i) => i)
}

export function getLast<T>(arr: readonly T[]): T | undefined {
  return arr[arr.length - 1]
}

export function groupBy<T, K extends keyof never>(
  arr: readonly T[],
  fn: (item: T) => K,
) {
  const result = {} as Record<K, T[]>
  for (const item of arr) {
    const key = fn(item)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const group = result[key] ?? []
    group.push(item)
    result[key] = group
  }
  return result
}

export function uniqueBy<T, K extends keyof never>(
  arr: readonly T[],
  fn: (item: T) => K,
) {
  const result = new Map<K, T>()
  for (const item of arr) {
    const key = fn(item)
    result.set(key, item)
  }
  return Array.from(result.values())
}

export function splitBy<T>(
  arr: readonly T[],
  predicate: (item: T) => boolean,
): [T[], T[]] {
  const result: [T[], T[]] = [[], []]
  for (const item of arr) {
    if (predicate(item)) {
      result[0].push(item)
    } else {
      result[1].push(item)
    }
  }
  return result
}

export function splitBySize<T>(arr: readonly T[], size: number): T[][] {
  const result: T[][] = []
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }
  return result
}

export function splitByCount<T>(arr: readonly T[], count: number): T[][] {
  const result: T[][] = []
  const chunkSize = Math.ceil(arr.length / count)
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize))
  }
  return result
}
export function shuffleArray<T>(arr: readonly T[]): T[] {
  const result = [...arr]
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = result[i]
    result[i] = result[j]
    result[j] = temp
  }
  return result
}

export function objectMap<T, R>(
  obj: Record<string, T>,
  fn: (item: T, key: string) => R,
) {
  const result = {} as Record<string, R>
  for (const key in obj) {
    result[key] = fn(obj[key], key)
  }
  return result
}
export function objectFilter<T>(
  obj: Record<string, T>,
  fn: (item: T, key: string) => boolean,
) {
  const result = {} as Record<string, T>
  for (const key in obj) {
    if (fn(obj[key], key)) {
      result[key] = obj[key]
    }
  }
  return result
}

export function debounce<T extends (...args: never[]) => void>(
  fn: T,
  delay: number,
): T {
  let timeout: ReturnType<typeof setTimeout>
  return ((...args: never[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }) as T
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function emptyFn() {}

export function toggleSet<T>(set: Set<T>, item: T) {
  const newSet = new Set(set)
  if (newSet.has(item)) {
    newSet.delete(item)
  } else {
    newSet.add(item)
  }
  return newSet
}
export function getValueByKey<T extends object, K extends keyof T>(
  obj: T,
  key: K | keyof never,
): T[K] | undefined {
  if (key in obj) {
    return obj[key as K]
  }
  return undefined
}

export function arrayRotate(arr: number[], count: number) {
  const n = count % arr.length
  return arr.slice(n, arr.length).concat(arr.slice(0, n))
}
export function smoothArray(
  arr: number[],
  n: number,
  increasePercent: number,
): number[] {
  const result: number[] = []
  for (let i = 0; i < arr.length; i += n) {
    let sum = 0
    for (let j = i; j < i + n && j < arr.length; j++) {
      sum += arr[j]
    }
    const average = sum / n
    const rest = average > 0.2 ? 1 - average : 0
    result.push(average + rest * increasePercent)
  }
  return result
}
export function setIntervalWithRAF(callback: () => void, interval: number) {
  let lastTime = performance.now()
  let requestId: number

  const loop = (time: number) => {
    requestId = requestAnimationFrame(loop)
    if (time - lastTime >= interval) {
      lastTime = time
      callback()
    }
  }

  requestId = requestAnimationFrame(loop)

  return () => {
    cancelAnimationFrame(requestId)
  }
}

export function isMobileByUserAgent() {
  const userAgent = navigator.userAgent
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent,
  )
}
export function startTimer() {
  const startTime = Date.now()
  return {
    stop: () => Date.now() - startTime,
  }
}

export function typedIncludes<T extends U, U>(
  coll: readonly T[],
  el: U,
): el is T {
  return coll.includes(el as T)
}

export function filterNil<T>(arr: readonly (T | undefined)[]): T[] {
  return arr.filter((item) => item !== undefined) as T[]
}
