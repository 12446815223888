import { proxy } from 'valtio'
import {
  ChatApi,
  ChatMessage,
  demoLessonTag,
  InfoBlockType,
  LessonStages,
  ProgramItem,
  Vocabulary,
} from '../../shared/api/chatApi.ts'
import { SpeechService } from '../speech/speechService.ts'
import { AuthStore } from '../auth/authStore.ts'
import {
  emptyFn,
  getLast,
  getTextTag,
  nilMap,
  randomElement,
  removeTextTag,
  sortBy,
  startTimer,
  waitFor,
} from '../../shared/lib/utils.ts'
import { FillWordText } from '../exercises/fillWords.tsx'
import { WordWithMeaning } from '../exercises/matchWordsStore.ts'
import { WriteTheGapsText } from '../exercises/writeTheGaps.tsx'
import { convertToId, toUppercase } from '../../shared/lib/stringUtils.ts'
import { urls } from '../../shared/urls.ts'
import { FillWordTextData } from '../exercises/fillTheGaps/fillTheGaps.tsx'
import { AppStore } from '../whatisnew/appStore.ts'

const responseTextTimeout = 10 * 1000
const responseTexts = [
  'Making up some magic for your answer... Hold on for just a moment',
  'Hang on! Our answer hamsters are running as fast as they can!',
  "Answer loading... In the meantime, here's a virtual high five for your patience! ✋",
  'Our answer chefs are cooking up something special. Please wait a bit...',
  "Brewing a fresh cup of reply. It'll be worth the wait, we promise!",
  "Just a sec, we're teaching our robots to talk faster!",
  "We're fishing for your response... Stay put!",
  'Mixing up a reply cocktail. Shaken, not stirred, and coming right up!',
  'Your answer is about to land 🛬',
]
type LoadingState = 'chat' | 'response' | 'record' | 'analyze' | 'command'

export interface ChatMessageWithIndex extends ChatMessage {
  index: number
  text: string
  content?: MessageContent
  stage?: string
  showTranslation?: boolean
  showHint?: boolean
}

const matchWords = [
  { word: 'Apple', synonym: 'A fruit' },
  { word: 'Dog', synonym: 'An animal' },
  { word: 'Car', synonym: 'A vehicle' },
  { word: 'Table', synonym: 'A furniture' },
  { word: 'Hammer', synonym: 'An instrument' },
]

const fillTheGaps: FillWordTextData[] = [
  {
    text: ['I cannot wait to', { word: 'take' }, 'a bite'],
    options: ['take', 'walk', 'jump'],
    incorrect: '',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    title: 'Fill the gaps',
  },
  {
    text: ['The', { word: 'cat' }, 'jumped over the fence'],
    options: ['cat', 'house', 'bird'],
    incorrect: '',
    title: 'Fill the gaps',
  },
  {
    text: ['She', { word: 'loves' }, 'to read books'],
    options: ['loves', 'speak', 'eat'],
    incorrect: '',
    title: 'Fill the gaps',
  },
  {
    text: ['The sun', { word: 'rises' }, 'in the east'],
    options: ['rises', 'sets', 'shines'],
    incorrect: '',
    title: 'Fill the gaps',
  },
  {
    text: ['He', { word: 'plays' }, 'the guitar every day'],
    options: ['plays', 'sings', 'dances'],
    incorrect: '',
    title: 'Fill the gaps',
  },
]

const fillWords = [
  ['I cannot wait to take a bite out of this juicy', { word: 'Apple' }, '.'],
  ['My', { word: 'Dog' }, 'is so excited to go for a walk. '],
  ['I love riding in the', { word: 'Car' }, 'with the windows down'],
]

const writeTheGaps = [
  [
    'She ',
    {
      hint: 'usual',
      word: 'usually',
    },
    ' goes to the gym on Mondays.',
  ],
  [
    'He always ',
    {
      hint: 'do',
      word: 'does',
    },
    ' his homework after dinner.',
  ],
  [
    'My brother ',
    {
      hint: 'work',
      word: 'works',
    },
    ' in a bank.',
  ],
]

export type MessageContent =
  | { image: string }
  | { text: string }
  | { fillWords: readonly FillWordText[] }
  | { fillTheGaps: readonly FillWordTextData[] }
  | { matchWords: readonly WordWithMeaning[] }
  | { writeTheGaps: readonly WriteTheGapsText[] }

export function isExercise(content: MessageContent | undefined) {
  if (!content) {
    return false
  }
  return (
    'fillTheGaps' in content ||
    'fillWords' in content ||
    'matchWords' in content ||
    'writeTheGaps' in content
  )
}

export function isText(
  content: MessageContent | undefined,
): content is { image: string } {
  if (!content) {
    return false
  }
  return 'text' in content
}

export function isImage(
  content: MessageContent | undefined,
): content is { image: string } {
  if (!content) {
    return false
  }
  return 'image' in content
}

interface State {
  exerciseResult?: string
  lastMessage: ChatMessageWithIndex
  languageLevel?: 'A2' | 'B1' | 'B2'
  userId?: number
  url?: string
  lastMessageId: number | undefined
  onlyTextInput: boolean
  program?: ProgramItem
  isDemo: boolean
  completed: boolean
  speakRussian: boolean
  vocabulary: Vocabulary[]
  selectedWords: { word: string; dark: boolean }[]
  showHistory: boolean
  showMessageTexts: boolean
  infoBlock?: InfoBlockType
  messages: ChatMessageWithIndex[]
  loading?: LoadingState
  programTag: string
  contentModal: boolean
  stages?: LessonStages
  currentStage?: string
  responseLoadingText?: string // TODO replace with matching
  currentText: string
  chatId: number
  loadingMessage: ChatMessageWithIndex | undefined
  showLoadingMessage: boolean
  sendButtonDisabled: boolean
  disabledByLoading: boolean
  chatInputUnavailable: boolean
  isExercise: boolean
  inputDisabled: boolean
  showChatBlock: boolean
  showTextInput: boolean
  mute: boolean
  playingMessage?: {
    messageId: number
    progress: number
  }
}

const defaultState = (chatId: number): State => ({
  get lastMessage() {
    return (
      this.messages[this.messages.length - 1] ?? {
        id: 1,
        message_text: '',
        text: '',
        is_ai: true,
        is_last: false,
      }
    )
  },
  get selectedWords() {
    return this.vocabulary.map((x) => ({
      word: x.term_text,
      dark: x.is_user_added,
    }))
  },
  get speakRussian() {
    if (this.languageLevel == 'A2') {
      return true
    }
    if (!this.program) {
      return false
    }
    return (
      this.program.level == 'A2' &&
      this.program.lesson_type == 'General conversation'
    )
  },
  get url() {
    if (this.isDemo) {
      return nilMap(this.lastMessage.state, (x) => urls.demo(convertToId(x)))
    }

    if (!this.currentStage) {
      return undefined
    }
    const id = convertToId(this.currentStage)
    return urls.lesson(this.programTag, id)
  },
  mute: false,
  showTextInput: false,
  get lastMessageId() {
    return getLast(this.messages)?.id
  },
  get onlyTextInput() {
    return this.infoBlock == 'demo_username'
  },
  showChatBlock: false,
  contentModal: false,
  get isDemo() {
    return this.programTag == demoLessonTag
  },
  vocabulary: [],
  programTag: '',
  get completed() {
    return (
      !!getLast(this.messages)?.is_last || this.program?.status == 'completed'
    )
  },
  showMessageTexts: true,
  showHistory: false,
  messages: [],
  currentText: '',
  chatId,

  get loadingMessage() {
    return this.showLoadingMessage
      ? {
          index: this.messages.length,
          message_text: '',
          text: '',
          is_ai: this.loading == 'response',
          id: -1,
          is_last: false,
        }
      : undefined
  },

  get showLoadingMessage() {
    const showLoadingMessageStates: LoadingState[] = ['response', 'analyze']
    return this.loading
      ? showLoadingMessageStates.includes(this.loading)
      : false
  },

  get isExercise() {
    const lastMessage = getLast(this.messages)
    return isExercise(lastMessage?.content)
  },
  get chatInputUnavailable() {
    return this.isExercise || this.completed
  },

  get disabledByLoading() {
    const completeButtonDisabledStates: LoadingState[] = [
      'response',
      'analyze',
      'command',
    ]
    return !!this.loading && completeButtonDisabledStates.includes(this.loading)
  },

  get sendButtonDisabled() {
    return this.disabledByLoading || this.chatInputUnavailable
  },
  get inputDisabled() {
    const lastMessage = getLast(this.messages)
    return this.completed || isExercise(lastMessage?.content)
  },
})
export class ChatStore {
  state: State

  constructor(
    private chatApi: ChatApi,
    private authStore: AuthStore,
    private speechService: SpeechService,
    private appStore: AppStore,
  ) {
    this.state = proxy<State>(defaultState(0))
  }

  async updateStage() {
    if (this.state.programTag) {
      const stage = await this.chatApi.userProgramStage(this.state.programTag)
      this.state.currentStage = stage.stage
    }
  }

  async newChat(tag: string) {
    if (!this.authStore.state.user) {
      return
    }
    try {
      this.state.programTag = tag
      this.resetState()
      await this.updateLanguageLevel()
      this.setLoading('chat')
      this.state.showChatBlock = true

      const [chat] = await Promise.all([
        this.chatApi.chat_create({
          user: this.authStore.state.user.user_id,
          program_tag: tag,
          chat_title: tag,
          language: 'EN',
        }),
        this.updateLanguageLevel(),
      ])
      await this.load(chat.id)
    } finally {
      this.state.loading = undefined
    }
  }

  async loadStages() {
    this.state.stages = await this.chatApi.userProgramStages(
      this.state.programTag,
    )
    await this.updateStage()
  }

  get currentTextIsEmpty() {
    return !this.state.currentText
  }

  removeLastMessage() {
    this.state.messages.pop()
  }

  addDemoLevelInfoBlock() {
    this.state.infoBlock = 'demo_level'
    this.state.loading = undefined
  }

  setLoading(loading: LoadingState) {
    this.state.loading = loading
  }

  async waitForFirstMessage() {
    await waitFor(() => this.state.messages.length > 0, 100)
  }

  setCurrentContent(message: ChatMessageWithIndex) {
    if (message.picture_url) {
      message.content = { image: message.picture_url }
      this.authStore.trackMetrika('demo2_photo_received')
      if (this.state.isDemo) {
        this.authStore.trackMetrika('picture_demo_sent')
      }
    }
    if (message.fill_the_gaps) {
      message.content = { fillWords: message.fill_the_gaps }
    }
    if (message.fill_the_gaps2) {
      const language = toUppercase(this.appStore.state.language)
      message.content = {
        fillTheGaps: message.fill_the_gaps2.map((x) => ({
          incorrect: x.incorrect[language],
          title: x.title[language],
          options: x.options,
          text: x.text,
        })),
      }
    }
    if (message.match_words) {
      message.content = { matchWords: message.match_words }
    }
    if (message.write_the_gaps) {
      message.content = { writeTheGaps: message.write_the_gaps }
    }
    if (message.info_block && message.info_block != 'none') {
      if (message.info_block != 'demo_level') {
        this.state.infoBlock = message.info_block
      }
    } else if (
      message.is_ai &&
      this.state.infoBlock &&
      message.info_block == 'none'
    ) {
      this.state.infoBlock = undefined
    }

    const textTag = getTextTag(message.message_text)
    if (textTag) {
      message.content = { text: textTag }
    }
  }
  addMessage(message: ChatMessage) {
    const text = removeTextTag(message.message_text)

    const newMessage = {
      ...message,
      text: text,
      index: this.state.messages.length,
    }
    this.setCurrentContent(newMessage)
    if (!newMessage.is_hidden) {
      this.state.messages.push(newMessage)
    }

    if (
      this.state.languageLevel == 'A2' &&
      newMessage.is_ai &&
      newMessage.translation
    ) {
      this.state.lastMessage.showTranslation = true
    }
    return newMessage
  }

  resetState() {
    this.state.messages = []
    this.state.infoBlock = undefined
  }

  async updateLanguageLevel() {
    const account = await this.chatApi.getAccount()
    this.setLanguageLevel(account.level)
  }

  async loadProgram() {
    if (this.state.isDemo) {
      return
    }
    const programs = await this.chatApi.userPrograms()
    this.state.program = programs.find((x) => x.tag == this.state.programTag)
  }
  async load(chatId: number) {
    if (this.state.chatId == chatId) {
      return
    }

    this.authStore.trackMetrika('demo2_lesson_launched')

    this.state.chatId = chatId
    this.setLoading('chat')

    const [messages] = await Promise.all([
      this.chatApi.message_list(this.state.chatId),
      this.loadProgram(),
      this.loadStages(),
      this.loadWords(),
    ])

    for (const m of sortBy(messages, (x) => x.id)) {
      this.addMessage(m)
    }

    this.state.loading = undefined
    if (this.authStore.state.user) {
      this.state.userId = this.authStore.state.user.user_id
    }
  }

  async sendCurrentText() {
    if (this.state.loading) {
      return
    }
    const text = this.state.currentText
    this.state.currentText = ''
    await this.send(text)
  }

  handleCommand(text: string) {
    if (text == '/text') {
      this.state.lastMessage.content = {
        text: 'Tom is in France on his first trip. He goes to a big supermarket to find food. He wants to buy milk, water, and some French cheese. Tom takes a basket and starts looking for the dairy section. He sees many types of cheese and picks one that looks tasty. ',
      }
    }
    if (text == '/end') {
      this.endChat()
    }
    if (text == '/fillgaps') {
      this.state.lastMessage.content = { fillTheGaps }
      this.state.showTextInput = false
    }
    if (text == '/task1') {
      this.state.lastMessage.content = { fillWords }
      this.state.showTextInput = false
    }
    if (text == '/task2') {
      this.state.lastMessage.content = { matchWords }
    }
    if (text == '/task3') {
      this.state.lastMessage.content = { writeTheGaps }
    }
    if (text == '/image') {
      this.state.lastMessage.content = {
        image:
          'https://img.dev.edman.ai/278/20240322/49b5e6fc-0a50-4faf-83bd-c10b2a94e906.png',
      }
    }
  }

  async send(text: string, hidden = false) {
    if (Boolean(this.state.loading) || !text) {
      return
    }

    if (text.startsWith('/')) {
      this.handleCommand(text)
      return
    }

    const stopResponseTextTimeout = this.startResponseTextTimeout()
    try {
      this.state.loading = 'response'

      await this.waitForFirstMessage()

      if (isImage(this.state.lastMessage.content)) {
        this.authStore.trackMetrika('demo2_photo_answered')
        if (this.state.isDemo) {
          this.authStore.trackMetrika('picture_demo_answer')
        }
      }

      if (isText(this.state.lastMessage.content)) {
        this.authStore.trackMetrika('demo2_text_answered')
        if (this.state.isDemo) {
          this.authStore.trackMetrika('picture_demo_answer')
        }
      }

      const lastMessage = this.state.lastMessage
      const fakeUserMessageId = lastMessage.id + 1
      const userMessage = this.addMessage({
        is_ai: false,
        message_text: text,
        is_hidden: hidden,
        id: fakeUserMessageId,
        is_last: false,
      })

      if (this.state.isDemo && this.state.infoBlock) {
        this.authStore.trackMetrika(`${this.state.infoBlock}_answer`)
        if (this.state.infoBlock == 'demo_subtopic') {
          this.state.showChatBlock = true
          this.state.messages = []
        }
        this.state.infoBlock = undefined
      }

      const data = {
        chat: this.state.chatId,
        message_text: text,
      }
      const timer = startTimer()
      const realUserMessageId = (await this.chatApi.message_create(data)).id
      userMessage.id = realUserMessageId
      const [newMessage] = await Promise.all([
        this.chatApi.message_process(realUserMessageId),
        this.updateAiComment(realUserMessageId),
      ])
      void this.chatApi.patchMessage(newMessage.id, {
        duration_front: timer.stop(),
      })
      stopResponseTextTimeout()
      this.addMessage(newMessage)
      void this.loadWords()
      void this.updateStage()

      this.state.loading = undefined
      await this.play(newMessage.id, false)
    } catch {
      this.removeLastMessage()
    } finally {
      stopResponseTextTimeout()
      this.state.loading = undefined
      if (this.state.completed) {
        this.authStore.trackMetrika('demo2_lesson_finished')
      }
    }
  }

  async updateAiComment(messageId: number) {
    if (this.state.isDemo) {
      return
    }
    const updateMessage = this.state.messages.find((x) => x.id == messageId)
    if (updateMessage) {
      const message = await this.chatApi.message_orthography_check(messageId)
      updateMessage.ai_comment = message.ai_comment
    }
  }

  async toggleMessageTranslate(id: number) {
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      if (message.showTranslation) {
        message.showTranslation = false
      } else {
        message.showTranslation = true
        const translation = await this.chatApi.message_translate(id)
        message.translation = translation.translation
      }
    }
  }

  async showMessageHint() {
    const id = this.state.lastMessage.id
    const message = this.state.messages.find((x) => x.id == id)
    if (message) {
      message.showHint = true
      if (!message.reply_hint) {
        const translation = await this.chatApi.message_reply_hint(id)
        message.reply_hint = translation.reply_hint
      }
    }
  }

  async play(messageId: number, forcePlay: boolean, hint = false) {
    const message = this.state.messages.find((x) => x.id == messageId)
    if (!message || (this.state.mute && !forcePlay)) {
      return
    }
    try {
      if (!forcePlay) {
        this.state.loading = 'command'
      }
      const text = hint ? message.reply_hint?.text : message.text
      if (!text) {
        return
      }
      const id = hint ? -messageId : messageId
      await this.speechService.playText(
        id,
        this.state.chatId,
        text,
        false,
        (progress) => {
          this.state.playingMessage = {
            messageId: message.id,
            progress,
          }
        },
      )
    } finally {
      if (!forcePlay) {
        this.state.loading = undefined
      }
    }
  }

  startResponseTextTimeout() {
    const id = setTimeout(() => {
      this.state.responseLoadingText = randomElement(responseTexts)
    }, responseTextTimeout)
    return () => {
      this.state.responseLoadingText = undefined
      clearTimeout(id)
    }
  }

  setCurrentText(text: string) {
    this.state.currentText = text
  }

  endChat() {
    this.state.lastMessage.is_last = true
  }

  async loadWords() {
    if (this.state.programTag) {
      const vocabulary = await this.chatApi.vocabulary(this.state.programTag)
      this.state.vocabulary = vocabulary
    }
  }

  async removeWord(id: number) {
    this.state.vocabulary = this.state.vocabulary.filter((x) => x.id != id)
    await this.chatApi.vocabulary_delete(id)
  }

  async handleWord(word: string, add: boolean) {
    if (add) {
      await this.addToDictionary(word)
    } else {
      const wordId = this.state.vocabulary.find((x) => x.term_text == word)?.id
      if (wordId) {
        await this.removeWord(wordId)
      }
    }
  }
  async addToDictionary(word: string) {
    const newWord: Vocabulary = {
      id: 0,
      term_text: word,
      date_added: '',
      date_last_reviewed: '',
      program_tag: '',
      topic: '',
      is_user_added: true,
    }
    this.state.vocabulary.push(newWord)
    const index = this.state.vocabulary.length - 1

    this.playWord(word)

    const updateWord = await this.chatApi.vocabulary_add({
      chat_id: this.state.chatId,
      term_text: word,
    })
    this.state.vocabulary[index] = updateWord
  }

  playWord(word: string) {
    void this.speechService.playText(
      undefined,
      this.state.chatId,
      word,
      false,
      emptyFn,
    )
  }

  onExerciseComplete(result: string) {
    this.authStore.trackMetrika('demo2_puzzle_passed')
    this.state.exerciseResult = result
  }

  continueAfterExercise() {
    if (!this.state.exerciseResult) {
      return
    }
    void this.send(this.state.exerciseResult, true)
    this.state.exerciseResult = undefined
  }

  setContentModal(contentModal: boolean) {
    this.state.contentModal = contentModal
  }

  toggleMute() {
    this.state.mute = !this.state.mute
  }
  setShowTextInput(showTextInput: boolean) {
    this.state.showTextInput = showTextInput
  }

  stopEdmanAudio() {
    this.speechService.stopCurrentSound()
  }

  rateChat(rating?: number, experience?: number, user_comment?: string) {
    return this.chatApi.chat_rate(
      this.state.chatId,
      rating,
      experience,
      user_comment,
    )
  }
  setLanguageLevel(level: 'A2' | 'B1' | 'B2') {
    this.state.languageLevel = level
  }
}
